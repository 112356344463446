//landing page
import React from 'react'
import { graphql } from 'gatsby'

import { CMSProvider, ThemesProvider } from '@happy-rabbit/gatsby-sanity-cms'

import Layout from '../parts/Layout'
import { useMetadata } from '../queries/metadata'
import PageView from '../parts/PageView'
const PageTemplate = (props) => {
  const { data, pageContext, path } = props

  const site = data.allSanitySite.edges[0].node
  const page = data.allSanityPage.edges[0].node
  const { pageConfig, pageDesign } = page
  const siteMetadata = useMetadata()

  const { themeReferences: themes } = site || {}

  const { lang } = pageContext

  return (
    <CMSProvider
      context={{ ...pageContext, pageConfig, page, path, site, siteMetadata }}
    >
      <ThemesProvider themes={themes}>
        <Layout lang={lang} pageDesign={pageDesign}>
          <PageView page={page} />
        </Layout>
      </ThemesProvider>
    </CMSProvider>
  )
}

export const pageQuery = graphql`
  query($siteId: String!, $pageId: String!) {
    allSanitySite(filter: { _id: { eq: $siteId } }) {
      edges {
        node {
          _id
          defaultCurrencyCode
          slug {
            current
          }
          themeReferences {
            ...SanityThemeFragment
          }
        }
      }
    }

    allSanityPage(filter: { _id: { eq: $pageId } }) {
      edges {
        node {
          _id
          _type
          pageDesign {
            ...SanityPageDesignFragment
          }
          pageSections {
            ... on SanityPageSectionsForm {
              ...SanityPageSectionsFormFragment
            }
            ... on SanityPageSectionsGrid {
              ...SanityPageSectionsGridFragment
            }
            ... on SanityPageSectionsHero {
              ...SanityPageSectionsHeroFragment
            }
            ... on SanityPageSectionsImage {
              ...SanityPageSectionsImageFragment
            }
            ... on SanityPageSectionsImgAndText {
              ...SanityPageSectionsImgAndTextFragment
            }
            ... on SanityPageSectionsLeadParagraph {
              ...SanityPageSectionsLeadParagraphFragment
            }
            ... on SanityPageSectionsNewsletter {
              ...SanityPageSectionsNewsletterFragment
            }
            ... on SanityPageSectionsParagraph {
              ...SanityPageSectionsParagraphFragment
            }
            ... on SanityPageSectionsProductIndex {
              ...SanityPageSectionsProductIndexFragment
            }
            ... on SanityPageSectionsPromotedStories {
              ...SanityPageSectionsPromotedStoriesFragment
            }
            ... on SanityPageSectionsQuickNav {
              ...SanityPageSectionsQuickNavFragment
            }
            ... on SanityPageSectionsSizeGuide {
              ...SanityPageSectionsSizeGuideFragment
            }
            ... on SanityPageSectionsStoryIndex {
              ...SanityPageSectionsStoryIndexFragment
            }
            ... on SanityPageSectionsTemplate {
              ...SanityPageSectionsTemplateFragment
            }
            ... on SanityPageSectionsTwoImages {
              ...SanityPageSectionsTwoImagesFragment
            }
            ... on SanityPageSectionsVimeo {
              ...SanityPageSectionsVimeoFragment
            }
            ... on SanityPageSectionsYoutube {
              ...SanityPageSectionsYoutubeFragment
            }
            ... on SanityPageSectionsIframeEmbed {
              ...SanityPageSectionsIframeEmbedFragment
            }
          }
          pageConfig {
            ...SanityPageConfigFragment
          }
        }
      }
    }
  }
`

export default PageTemplate
